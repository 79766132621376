import React from 'react'
import './experience.css'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Here's some of</h5>
      <h2>My Experience</h2>

      <div className='container experience__container'>
        <div className='experience__nasa'>
          <h3>AR/VR Software Development Intern</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <h4>NASA (Summer 2023)</h4>
              <p>
                As an intern at NASA this summer, I'm currently developing some AR/VR software applications using Unity Engine and C# 
                that help the scientists and engineers at NASA do jobs that are normally not possible without going to space. I'm 
                building tape measuring tools, rulers, and cable installation tools with realistic physics in Virtual Reality applications
                so astronauts and mechanical engineers can see the inside of objects currently deployed in space like shuttles, satellites, 
                and more, all from the safety of NASA labratories back on earth. While using these tools I am developing VR, engineers can
                simulate complex procedures in space like measuring the size of internal parts of shuttles and enginers or laying cables inside a satellite.
              </p>
            </article>
          </div>
        </div>
        <div className='experience__appstop'>
          <h3>Product Manager Intern</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <h4>AppStop (Summer 2023)</h4>
              <p>
                In addition to my position at NASA this summer, I am also working as a product manager at the mobile game studio AppStop, 
                which develops AdverGames for a variety of brands. As the sole Product Manager for the mobile game "One Tap Victory Lap"
                (check it out on the app store!). I'm responsible for gameplay critique/analysis, designing core game features, and player conversion
                analysis to maximize player traffic. The game currently has over 10,000 downloads, and counting! When it comes to business development,
                I work with content creators who have agreed to promote the game to help come up with major media promotions and fully launch them. 
                In addition, I lead the game developer team, organizing and leading meetings on fast-paced timelines to meet deadlines. I work on with developers 
                on features from conceptualization to launch, delegating tasks to developers based on current company priorities and keeping the team
                on track for release dates.
              </p>
            </article>
          </div>
        </div>
        <div className='experience__totalcae'>
          <h3>Software Development Intern</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <h4>TotalCAE (Summer 2022)</h4>
              <p>
                At TotalCAE, I developed an automated drop-in replacement program for SLURM (a Job Scheduler for Linux) 
                that gives clients enhanced details such as ID, Name, Job, Partition, etc. on submitted jobs automatically
                via email. Another one of my responsibilities was to optimize and modify pre-existing job-scheduling code to
                allow it to support a much heavier load and drastically improve runtime. Additionally, I implemented 
                new customization features for clients such as a configurable tail-file size to job emails, support for multiple
                file types, and an adjustable verbosity level.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience